<!--
 * @version: v 1.0.0
 * @Github: https://github.com/GitHubGanKai
 * @Author: GitHubGanKai
 * @Date: 2021-01-01 13:57:27
 * @LastEditors: gankai
 * @LastEditTime: 2021-01-01 16:38:30
 * @FilePath: /vue-jd-h5/src/views/error/NoPermission.vue
-->
<template>
  <div class="container">NoPermission</div>
</template>

<script>
export default {
  name: "noPermission",
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>

<style scoped lang="scss"></style>
